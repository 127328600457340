.arrow-up {
	width: 0; 
	height: 0;
	display: inline-block;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid $etn-green;
}

.arrow-down {
	width: 0; 
	height: 0; 
	display: inline-block;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid $etn-green;
}
	
.arrow-right {
	width: 0; 
	height: 0; 
	display: inline-block;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 6px solid $etn-green;
}

.arrow-left {
	width: 0; 
	height: 0; 
	display: inline-block;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent; 
	border-right:6px solid $etn-green; 
}
	
.arrow-none {
	width: 0; 
	height: 0; 
	display: inline-block;
}
