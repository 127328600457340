.orange {
	color: #ffa700;
}

.red {
	color: #d62d20;
}

.etn-green {
	color: $brand-primary;
}